import MDGiveReportComponent from '../pages/give/reports/'

const MDGiveReport = () => {
  return (
    <>
      <MDGiveReportComponent />
    </>
  )
}

export default MDGiveReport
